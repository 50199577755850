<template>
	<div>
		<!-- 第一列 -->
		<el-form class="left" ref="defForm" :rules="rules" :model="defForm" label-width="120px">
			<el-form-item label="栏目名称" prop="name">
				<el-input v-model="defForm.name"></el-input>
			</el-form-item>
			<el-form-item label="父级栏目" prop="parentId">
				<treeselect v-model="defForm.parentId" :multiple="false" placeholder="请选择父栏目" :options="treeData" />
			</el-form-item>
			<el-form-item label="栏目类型" prop="type">
				<el-select v-model="defForm.type" placeholder="请选择栏目类型">
					<el-option v-for="item in this.typeList" :key="item.id" :label="item.label" :value="item.id">
					</el-option>
				</el-select>
			</el-form-item>
			<el-form-item label="栏目位置" prop="position">
				<el-select v-model="defForm.position" placeholder="请选择栏目位置">
					<el-option v-for="item in this.posList" :key="item" :label="item" :value="item">
					</el-option>
				</el-select>
			</el-form-item>
		</el-form>
		<!-- 第二列 -->
		<el-form class="right" ref="defForm" :rules="rules" :model="defForm" label-width="120px">
			<el-form-item label="栏目图片" prop="image">
				<el-upload class="avatar-uploader" action="string" :show-file-list="false" :http-request="uploadImg"
					:before-upload="beforeAvatarUpload">
					<img v-if="defForm.image" :src="defForm.image" class="avatar">
					<i v-else class="el-icon-plus avatar-uploader-icon"></i>
				</el-upload>
			</el-form-item>
			<el-form-item label="功能描述" prop="summary">
				<el-input v-model="defForm.summary"></el-input>
			</el-form-item>
			<el-form-item label="是否单页" prop="single">
				<el-switch v-model="defForm.single" active-color="#13ce66" inactive-color="#999999">
				</el-switch>
			</el-form-item>
		</el-form>
		<!-- 编辑器 -->
		<el-form ref="defForm" :rules="rules" :model="defForm" label-width="120px">
			<el-form-item label="栏目内容" prop="content">
				<v-md-editor v-model="defForm.content" height="300px"></v-md-editor>
			</el-form-item>
		</el-form>
		<!-- 按钮部分 -->
		<div class="button">
			<el-button type="primary" @click="$router.back()">返回</el-button>
			<el-button type="primary" :loading="loading" @click="submitForm(defForm)">保存</el-button>
		</div>
	</div>
</template>
<script>
	import Treeselect from '@riophae/vue-treeselect'
	import '@riophae/vue-treeselect/dist/vue-treeselect.css'
	import token from '@/token.js'
	import {
		update,
		tree,
		upload,
		detail
	} from '@/api/channel'
	export default {
		name: "Edit",
		components: {
			Treeselect
		},
		data() {
			return {
				defForm: {
					id: 0,
					name: '',
					parentId: null,
					image: '',
					summary: '',
					single: false,
					type: '',
					position: '',
					content: '',
					updateUserID: token.getUser().id,
					updateUserName: token.getUser().manager_username
				},
				typeList:[
					{
						id:'nav',
						label:'导航栏'
					},
					{
						id:'tab',
						label:'标签页'
					}
				],
				posList: ['A', 'B', 'C', 'D', 'E', 'F', 'G'],
				treeData: [],
				loading: false,
				rules: {
					name: [{
						required: true,
						message: '必填项不能为空'
					}],
					image: [{
						required: true,
						message: '必填项不能为空'
					}],
					position: [{
						required: true,
						message: '必填项不能为空'
					}],
					type: [{
						required: true,
						message: '必填项不能为空'
					}],
				}
			}
		},

		created() {
			let channelInfo = this.$route.query.channelInfo
			this.defForm.id=channelInfo.id,
			this.defForm.name=channelInfo.name,
			this.defForm.parentId=channelInfo.parentId,
			this.defForm.image=channelInfo.image,
			this.defForm.summary=channelInfo.summary,
			this.defForm.single=channelInfo.single,
			this.defForm.type=channelInfo.type,
			this.defForm.position=channelInfo.position,
			this.defForm.content=channelInfo.content==null ? '': channelInfo.content,
			// console.log(this.defForm)
			tree().then(data => {
				this.treeData = data.data
			}).catch(error => {
				this.$message.error("树级栏目获取失败！")
			})
		},
		methods: {
			submitForm(form) {
				this.$refs['defForm'].validate(valid => {
					if (valid) {
						this.loading = true
						if(this.defForm.parentId==null){
							this.defForm.parentId=0;
						}
						console.log(this.defForm)
						update(this.defForm).then(data => {
							this.loading = false
							this.$message.success("栏目["+this.defForm.name+"]修改成功!")
							this.$router.push('/admin/content/channelindex')
						}).catch(error => {
							this.loading = false
							this.$message.error(error)
						})
					}
				})
			},
			close() {
				this.$emit("hideDialog")
			},
			uploadImg(param) {
				let formData = new FormData();
				formData.append("file", param.file)
				upload(formData).then(data => {
					this.defForm.image = data.data
				}).catch(error => {
					this.$message.error(error)
				})
			},
			//上传之前的验证
			beforeAvatarUpload(file) {
				const isIMAGE = (file.type === 'image/jpeg') || (file.type === 'image/jpg') || (file.type === 'image/png')
				if (!isIMAGE) {
					this.$message.error('上传文件只能是图片格式!')
				}
				return isIMAGE 
			},
		}
	}
</script>

<style scoped>
	.left{
		width: 50%;
		display: inline-block;
	}
	.right{
		width: 50%;
		display: inline-block;
	}
	.avatar-uploader {
		border: 1px dashed #d9d9d9;
		border-radius: 6px;
		cursor: pointer;
		width: 80px;
		height: 80px;
		position: relative;
		overflow: hidden;
	}

	.avatar-uploader:hover {
		border-color: #409EFF;
	}

	.avatar-uploader-icon {
		font-size: 28px;
		color: #8c939d;
		width: 80px;
		height: 80px;
		line-height: 80px;
		text-align: center;
	}

	.avatar {
		width: 80px;
		height: 80px;
		display: block;
	}
</style>
